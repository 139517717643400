<template>
    <div>
        <navheader></navheader>
        <div class="notfound-box">
            <div class="notfound-img">
                <img src="../assets/img/notfoundImg.png" alt="">
                <a @click="jumpMyClass">
                    返回首页
                </a>
            </div>
        </div>
        <navfooter></navfooter>
    </div>
</template>

<script>
import navheader from '../components/NavHeader'
import navfooter from '../components/NavFooter'
export default {
    name: 'notfound',
    components: {
        navheader,
        navfooter,
    },
    metaInfo: {
        title: '页面不存在-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    methods: {
        jumpMyClass() {
            this.$router.push({
                name:'index'
            })
      },
    }
}
</script>

<style lang="scss">
    .notfound-box {
        padding-top: 88px;
        padding-bottom: 88px;
        box-sizing: border-box;
        .notfound-img {
            position: relative;
            margin: 0 auto;
            width: 1373px;
            height: 992px;
            a {
                display: block;
                position: absolute;
                left: 50%;
                margin-left: -125px;
                bottom: 149px;
                width: 267px;
                height: 82px;
                background: #fa4b66;
                border-radius: 50px;
                font-size: 24px;
                color: #ffffff;
                text-align: center;
                line-height: 82px;
            }
        }
    }
</style>